import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Button, Stack, ComponentWrapper } from 'ascential-horizon'
import moment from 'moment'
import { useIdentity } from '../providers/identityContext'
import { windowExists } from '../utils'
import Layout from '../Layout'

function UserStatus() {
  const data = useStaticQuery(graphql`
    query {
      contentfulHeader(instanceName: { eq: "Main Header" }) {
        primaryNavigation {
          item {
            label
            url
          }
          subNavigation {
            navItems {
              label
              url
            }
          }
        }
        utilityNavigation {
          label
          url
        }
        userLink
        ctaLabel
        ctaUrl
      }
      contentfulFooter(instanceName: { eq: "Main Footer" }) {
        columns {
          ... on ContentfulNavigation {
            instanceName
            navItems {
              label
              url
            }
          }
          ... on ContentfulTextBlock {
            heading
            copy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        utilityNavigation {
          navItems {
            label
            url
          }
        }
        socialLabel
        facebookUrl
        twitterUrl
        linkedInUrl
        youTubeUrl
        instagramUrl
      }
    }
  `)

  const { user } = useIdentity()

  return (
    windowExists && (
      <Layout
        header={data.contentfulHeader}
        footer={data.contentfulFooter}
        slug="/cleanup"
        pageType="/">
        <ComponentWrapper mb="large">
          <Stack space="xlarge">
            <h3>
              Your status at {moment(Date.now()).format('h:mm a, MMMM Do YYYY')}
            </h3>
            {user.loggedIn ? (
              <p>
                You are logged using {user.email}.{' '}
                <a href="/cleanup">Click here to logout</a>.
              </p>
            ) : (
              <p>You are not currently logged in.</p>
            )}
            <p>Your browser details: {navigator.userAgent}</p>
            <Button url="/">Return Home</Button>
          </Stack>
        </ComponentWrapper>
      </Layout>
    )
  )
}

export default UserStatus
